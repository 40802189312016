import { setCompanies } from "../../reducers/dashboardSlice";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import * as actionTypes from "../actions/actions";
import { store } from "../store";

export const getAllOrgNames = (action: any) => async (dispatch: any) => {
  const orgName = store.getState().getCompanyNames.companyNames;
  const reload = action.reload ? action.reload : false;
  try {
    if (orgName?.length && !reload) {
      action.callback(orgName);
    } else {
      dispatch({
        type: actionTypes.LOAD_COMPANYNAMES_REQUEST,
      });
      const resp = await action.api.get(
        `/api/reservation/filter/companyNames?saasOrgId=${LocalStorageUtil.getItem(
          "saasOrgId"
        )}`
      );
      if (resp.data) {
        const arr: any = [{ orgName: "All", orgId: "All" }, ...resp.data];
        dispatch({
          type: actionTypes.LOAD_COMPANYNAMES_SUCCESS,
          payload: arr,
        });
        action.callback(arr);
      }
    }
  } catch (error) {
    dispatch({
      type: actionTypes.LOAD_COMPANYNAMES_FAILURE,
    });
    console.log(error, "ERR");
  }
};

export const getAllOrgDetails = (action: any) => async (dispatch: any) => {
  const allOrgName = store.getState().getCompanyNames.allOrganizations;
  // const reload = action.reload ? action.reload : false;
  const reload = store?.getState()?.getCompanyNames?.isReload;

  try {
    if (LocalStorageUtil.getItem("root") === "true") {
      if (allOrgName?.length && !reload) {
        action.callback(allOrgName);
      } else {
        dispatch({
          type: actionTypes.LOAD_COMPANYNAMES_REQUEST,
        });
        let resp = await action.api.get(`/api/organization/getAllOrganization`);
        if (resp.data) {
          const orgs = resp.data?.organizationApiList?.content
            ?.filter(
              (org: any) =>
                action.ignoreFilter ||
                (org.isOrgApproved === "Approved" && org.orgStatus === "Active")
            )
            .map((item: any) => {
              return {
                orgId: item.orgId,
                orgName: item.orgName,
                saasOrgId: item.saasOrgId,
                orgStatus: item.orgStatus,
                usageType: item.usageType,
                isOrgApproved: item.isOrgApproved,
              };
            });
          dispatch({
            type: actionTypes.LOAD_ORGANIZATIONS_SUCCESS,
            payload: orgs,
          });
          action.callback(orgs);
        }
      }
    } else {
      try {
        let resp = await action.api.get(`/api/organization/getAllOrganization`);
        if (resp.data) {
          const orgs = resp?.data?.organizationApiList?.content
            ?.filter(
              (org: any) =>
                org.isOrgApproved === "Approved" && org.orgStatus === "Active"
            )
            .map((item: any) => {
              return {
                orgId: item.orgId,
                orgName: item.orgName,
                saasOrgId: item.saasOrgId,
                orgStatus: item.orgStatus,
                usageType: item.usageType,
                isOrgApproved: item.isOrgApproved,
              };
            });
          dispatch(setCompanies(orgs));
          action.callback(orgs);
        }
      } catch (err) {
        console.log(err);
      }

      // action.callback([
      //   {
      //     orgId: JSON.parse(String(LocalStorageUtil.getItem("saasOrgId"))),
      //     orgName: JSON.parse(String(LocalStorageUtil.getItem("orgName"))),
      //     saasOrgId: JSON.parse(String(LocalStorageUtil.getItem("saasOrgId"))),
      //   },
      // ]);
    }
  } catch (error) {
    dispatch({
      type: actionTypes.LOAD_COMPANYNAMES_FAILURE,
    });
    console.log(error, "ERR");
  }
};
