import { useSidebarContext } from "../../Contexts/SidebarContext";
import { getMessageText } from "../message";
import * as LangConst from "../../utils/constant/LangConst";
import CarrierNetworkConfig from "../../pages/AdminPages/carrierNetworkConfig";

// export const dashboaradPages = [
//   {
//     id: 21,
//     pageName: "Finpark Reserve",
//     role: "user",
//     pageRoute: "/reserve",
//     iconClass: "ri-bill-line",
//     view: ["admin", "user"],
//   },
//   {
//     id: 1,
//     pageName: "Company Dashboard",
//     role: "user",
//     pageRoute: "/dashboard",
//     iconClass: "ri-dashboard-line",
//     view: ["admin", "user"],
//     childrenRoute: [
//       {
//         id: "",
//         pageName: "",
//         pageRoute: "",
//       },
//     ],
//   },
//   {
//     id: 2,
//     pageName: "Map Dashboard",
//     role: "user",
//     pageRoute: "/mapDashboard",
//     iconClass: "ri-road-map-line",
//     view: ["admin", "user"],
//     childrenRoute: [
//       {
//         id: "",
//         pageName: "",
//         pageRoute: "",
//       },
//     ],
//   },
//   {
//     id: 3,
//     pageName: "Invoice Dashboard",
//     role: "user",
//     pageRoute: "/invoiceDashboard",
//     iconClass: "ri-bill-line",
//     view: ["admin", "user"],
//     childrenRoute: [
//       {
//         id: "",
//         pageName: "",
//         pageRoute: "",
//       },
//     ],
//   },
//   {
//     id: 4,
//     pageName: "SOI Dashboard",
//     role: "user",
//     pageRoute: "/soiDashboard",
//     iconClass: "ri-bill-line",
//     view: ["admin", "user"],
//     childrenRoute: [
//       {
//         id: "",
//         pageName: "",
//         pageRoute: "",
//       },
//     ],
//   },
//   {
//     id: 5,
//     pageName: "Administrator",
//     role: "admin",
//     iconClass: "ri-settings-line",
//     childrenRoute: [
//       {
//         id: 6,
//         pageName: "Organizations",
//         pageRoute: "/organizationDetails",
//         view: ["admin"],
//       },
//       {
//         id: 7,
//         pageName: "Company Profile",
//         pageRoute: "/companyProfile",
//         view: ["user"],
//       },
//       {
//         id: 8,
//         pageName: "Amenities",
//         pageRoute: "/amenities",
//         view: ["admin", "user"],
//       },
//       {
//         id: 9,
//         pageName: "Parking Lot",
//         pageRoute: "/parkingLot",
//         view: ["admin", "user"],
//       },
//       {
//         id: 15,
//         pageName: "Assets",
//         pageRoute: "/assets",
//         view: ["admin", "user"],
//       },
//       {
//         id: 14,
//         pageName: "Asset Type",
//         pageRoute: "/assetType",
//         view: ["admin"],
//       },
//       {
//         id: 16,
//         pageName: "Equipment Type",
//         pageRoute: "/equipmentType",
//         view: ["admin"],
//       },
//     ],
//   },
//   {
//     id: 10,
//     pageName: "Reports",
//     role: "admin",
//     iconClass: "ri-file-chart-line",
//     childrenRoute: [
//       {
//         id: 11,
//         pageName: "Reservations",
//         pageRoute: "/reservations",
//       },
//       {
//         id: 12,
//         pageName: "Drivers",
//         pageRoute: "/drivers",
//       },
//       {
//         id: 13,
//         pageName: "Parking Lot Pricing",
//         pageRoute: "/parkinglotpricing",
//       },
//     ],
//   },
// ];

export const searchTree = (element, matchingTitle, field) => {
  if (element[field] === matchingTitle) {
    return element;
  } else if (element.childrenRoute != null) {
    var i;
    var result = null;
    for (i = 0; result == null && i < element.childrenRoute.length; i++) {
      result = searchTree(element.childrenRoute[i], matchingTitle, field);
    }
    return { parent: element, current: result };
  }
  return null;
};

export const dashPage = (user) => {
  return [
    {
      id: 1,
      pageName: getMessageText(LangConst.FINPARK_RESERVE, "Finpark Reserve"),
      pageNameCode: LangConst.FINPARK_RESERVE,
      role: "user",
      pageRoute: "/reserve",
      iconClass: "ri-reserved-line",
      view: "both",
      orgType: ["CARRIER", "BROKER"],
    },
    {
      id: 2,
      pageName: getMessageText(
        LangConst.COMPANY_DASHBOARD,
        "Company Dashboard"
      ),
      pageNameCode: LangConst.COMPANY_DASHBOARD,
      role: "user",
      pageRoute: "/dashboard",
      iconClass: "ri-dashboard-line",
      view: "both",
      orgType: ["PARKING_LOT_OWNER"],
    },
    {
      id: 3,
      pageName: getMessageText(LangConst.MAP_DASHBOARD, "Map Dashboard"),
      pageNameCode: LangConst.MAP_DASHBOARD,
      role: "user",
      pageRoute: "/mapDashboard",
      iconClass: "ri-road-map-line",
      view: "both",
      orgType: ["PARKING_LOT_OWNER"],
    },
    {
      id: 4,
      pageName: getMessageText(
        LangConst.INVOICE_DASHBOARD,
        "Invoice Dashboard"
      ),
      pageNameCode: LangConst.INVOICE_DASHBOARD,
      role: "user",
      pageRoute: "/invoiceDashboard",
      iconClass: "ri-bill-line",
      notFor: "Manager",
      view: "both",
      orgType: ["CARRIER", "PARKING_LOT_OWNER", "BROKER"],
    },
    {
      id: 5,
      pageName: getMessageText(
        LangConst.SOI_DASHBOARD,
        "Statement Of Income Dashboard"
      ),
      pageNameCode: LangConst.SOI_DASHBOARD,
      role: "user",
      pageRoute: "/soiDashboard",
      iconClass: "ri-dashboard-line",
      view: "both",
      notFor: "Manager",
      orgType: ["PARKING_LOT_OWNER"],
    },
    {
      id: 6,
      pageName: getMessageText(
        LangConst.DISCOUNT_INVOICE,
        "Discount Code Invoice"
      ),
      pageNameCode: LangConst.DISCOUNT_INVOICE,
      role: "user",
      pageRoute: "/discountcodeinvoice",
      iconClass: "ri-percent-line",
      view: "both",
      notFor: "Manager",
      orgType: ["PARKING_LOT_OWNER"],
    },
    {
      id: 7,
      pageName: getMessageText(LangConst.ADMINISTRATOR, "Administrator"),
      pageNameCode: LangConst.ADMINISTRATOR,
      role: "admin",
      view: "both",
      iconClass: "ri-settings-line",
      childrenRoute: [
        {
          id: 8,
          pageName: getMessageText(LangConst.ORGANIZATIONS, "Organizations"),
          pageNameCode: LangConst.ORGANIZATIONS,
          pageRoute: "/organizationDetails",
          innerRoute: "/companyProfile",
          view: "admin",
          orgType: ["PARKING_LOT_OWNER"],
        },
        {
          id: 9,
          pageName: getMessageText(
            LangConst.COMPANY_PROFILE,
            "Company Profiles"
          ),
          pageNameCode: LangConst.COMPANY_PROFILE,
          pageRoute: "/companyProfile",
          view: "both",
          orgType: ["CARRIER", "PARKING_LOT_OWNER", "BROKER"],
        },
        {
          id: 10,
          pageName: getMessageText(LangConst.AMENITIES, "Amenities"),
          pageNameCode: LangConst.AMENITIES,
          pageRoute: "/amenities",
          view: "admin",
          orgType: [],
        },
        {
          id: 11,
          pageName: getMessageText(LangConst.PARKING_LOT, "Parking Lot"),
          pageNameCode: LangConst.PARKING_LOT,
          pageRoute: "/parkingLot",
          innerRoute: "/parkingInfo",
          view: "both",
          orgType: ["PARKING_LOT_OWNER"],
        },
        {
          id: 12,
          pageName: getMessageText(LangConst.DRIVER, "Driver"),
          pageNameCode: LangConst.DRIVER,
          pageRoute: "/createDriver",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 13,
          pageName: getMessageText(
            LangConst.PARKING_ACCESS_CODE,
            "Parking Access Code"
          ),
          pageNameCode: LangConst.PARKING_ACCESS_CODE,
          pageRoute: "/parkinglotAccessCode",
          view: "both",
          orgType: ["PARKING_LOT_OWNER"],
        },
        {
          id: 14,
          pageName: getMessageText(LangConst.DISCOUNT_CODE, "Discount code"),
          pageNameCode: LangConst.DISCOUNT_CODE,
          pageRoute: "/discountCode",
          view: "both",
          notFor: "Manager",
          orgType: ["PARKING_LOT_OWNER", "CARRIER", "BROKER"],
        },
        {
          id: 15,
          pageName: getMessageText(
            LangConst.BOOK_RESERVATION,
            "Book Reservation"
          ),
          pageNameCode: LangConst.BOOK_RESERVATION,
          pageRoute: "/bookReservations",
          view: "admin",
          orgType: ["PARKING_LOT_OWNER"],
        },
        {
          id: 16,
          pageName: getMessageText(LangConst.ASSETS, "Assets"),
          pageNameCode: LangConst.ASSETS,
          pageRoute: "/assets",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 17,
          pageName: getMessageText(LangConst.ASSET_TYPE, "Asset Type"),
          pageNameCode: LangConst.ASSET_TYPE,
          pageRoute: "/assetType",
          view: "admin",
          orgType: [],
        },
        {
          id: 18,
          pageName: getMessageText(LangConst.EQUIPMENT_TYPE, "Equipment Type"),
          pageNameCode: LangConst.EQUIPMENT_TYPE,
          pageRoute: "/equipmentType",
          view: "admin",
          orgType: [],
        },
      ],
    },
    {
      id: 19,
      pageName: getMessageText(LangConst.CARRIER_NETWORK, "Carrier Network"),
      pageNameCode: LangConst.CARRIER_NETWORK,
      role: "admin",
      view: "user",
      carrierNetwork: true,
      iconClass: "ri-settings-line",
      childrenRoute: [
        {
          id: 20,
          pageName: getMessageText(
            LangConst.CARRIER_ORGANIZATION,
            "Carrier Organization"
          ),
          pageNameCode: LangConst.CARRIER_ORGANIZATION,
          pageRoute: "/carrierOrganization",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 21,
          pageName: getMessageText(LangConst.ADD_CARRIER, "Add Carrier"),
          pageNameCode: LangConst.ADD_CARRIER,
          pageRoute: "/addCarrier",
          view: "user",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 22,
          pageName: getMessageText(LangConst.CARRIER_ASSETS, "Carrier Assets"),
          pageNameCode: LangConst.CARRIER_ASSETS,
          pageRoute: "/carrierAssets",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 23,
          pageName: getMessageText(
            LangConst.SUBSCRIPTION_INVOICE,
            "Subscription invoice"
          ),
          pageNameCode: LangConst.SUBSCRIPTION_INVOICE,
          pageRoute: "/sinvoice",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
      ],
    },
    {
      id: 24,
      pageName: getMessageText(LangConst.REPORTS, "Reports"),
      pageNameCode: LangConst.REPORTS,
      role: "admin",
      view: "both",
      iconClass: "ri-file-chart-line",
      childrenRoute: [
        {
          id: 25,
          pageName: getMessageText(LangConst.RESERVATIONS, "Reservations"),
          pageNameCode: LangConst.RESERVATIONS,
          pageRoute: "/reservations",
          innerRoute: "/reservationparkdetails",
          view: "both",
          orgType: ["CARRIER", "PARKING_LOT_OWNER", "BROKER"],
        },
        {
          id: 26,
          pageName: getMessageText(LangConst.DRIVERS, "Drivers"),
          pageNameCode: LangConst.DRIVERS,
          pageRoute: "/drivers",
          view: "admin",
          orgType: [],
        },
        {
          id: 27,
          pageName: getMessageText(
            LangConst.PARKING_LOT_PRICING,
            "Parking Lot Pricing"
          ),
          pageNameCode: LangConst.PARKING_LOT_PRICING,
          pageRoute: "/parkinglotpricing",
          view: "both",
          orgType: ["PARKING_LOT_OWNER"],
        },
        {
          id: 28,
          pageName: getMessageText(LangConst.REVENUE, "Revenue"),
          pageNameCode: LangConst.REVENUE,
          pageRoute: "/revenue",
          view: "admin",
          orgType: [],
        },
        {
          id: 29,
          pageName: getMessageText(LangConst.SUBSCRIPTIONS, "Subscriptions"),
          pageNameCode: LangConst.SUBSCRIPTIONS,
          pageRoute: "/subscription",
          view: "admin",
          orgType: [],
        },
        {
          id: 30,
          pageName: getMessageText(LangConst.ASSETS, "Assets"),
          pageNameCode: LangConst.ASSETS,
          pageRoute: "/assetReport",
          view: "both",
          orgType: ["CARRIER", "BROKER"],
        },
        {
          id: 31,
          pageName: getMessageText(LangConst.PARKING_LOT, "Parking Lot"),
          pageNameCode: LangConst.PARKING_LOT,
          pageRoute: "/parkingLotReport",
          view: "both",
          orgType: ["PARKING_LOT_OWNER"],
        },
      ],
    },
    {
      id: 32,
      pageName: getMessageText(LangConst.API_HUB, "API HUB"),
      pageNameCode: LangConst.API_HUB,
      role: "user",
      pageRoute: "/apihub",
      iconClass: "ri-dashboard-line",
      view: "both",
      orgType: ["CARRIER", "BROKER"],
    },
    // {
    //   id: 33,
    //   pageName: "New Map DashBoard",
    //   pageNameCode: "New Map DashBoard",
    //   role: "user",
    //   pageRoute: "/newMap",
    //   iconClass: "ri-road-map-line",
    //   view: "both",
    //   orgType: ["PARKING_LOT_OWNER"],
    // },
  ];
};

export const timeZoneValues = [
  // {name : "HST", value: "America/Adak",},
  // { name :"AST", value: "America/Anchorage",},
  { name: "MST", value: "America/Boise" },
  // {name :"PNT", value: "America/Phoenix",},
  { name: "CST", value: "America/Chicago" },
  { name: "EST", value: "America/Atikokan" },
  // {name :"IET", value: "America/Indiana/Indianapolis",},
  { name: "PST", value: "America/Los_Angeles" },
];

export const imageData = [
  { imgPath: "./assets/images/wifi.svg", name: "Wifi", title: "Wifi", id: 1 },

  {
    imgPath: "./assets/images/shower.svg",
    name: "Shower",
    title: "Shower",
    id: 2,
  },

  {
    imgPath: "./assets/images/restarent.svg",
    name: "Restarent",
    title: "Restaurent",
    id: 3,
  },

  {
    imgPath: "./assets/images/parking.svg",
    name: "Parking",
    title: "Parking",
    id: 4,
  },

  {
    imgPath: "./assets/images/gas-station.svg",
    name: "GasStation",
    title: "GasStation",
    id: 5,
  },

  {
    imgPath: "./assets/images/truck.svg",
    name: "Truck",
    title: "Truck",
    id: 6,
  },

  {
    imgPath: "./assets/images/repair.svg",
    name: "Repair",
    title: "Repair",
    id: 7,
  },

  {
    imgPath: "./assets/images/drugstore.svg",
    name: "DrugStore",
    title: "DrugStore",
    id: 8,
  },

  { imgPath: "./assets/images/ATM.svg", name: "ATM", title: "ATM", id: 9 },

  {
    imgPath: "./assets/images/CAT scales.svg",
    name: "CAT scales",
    title: "CAT scales",
    id: 10,
  },

  {
    imgPath: "./assets/images/Driver lounge.svg",
    name: "Driver lounge",
    title: "Driver lounge",
    id: 11,
  },

  {
    imgPath: "./assets/images/Laundry Service.svg",
    name: "Laundry Service",
    title: "Laundry Service",
    id: 12,
  },

  {
    imgPath: "./assets/images/Parts & supply.svg",
    name: "Parts and supply",
    title: "Parts & supply",
    id: 13,
  },

  {
    imgPath: "./assets/images/Truck wash.svg",
    name: "Truck wash",
    title: "Truck wash",
    id: 14,
  },

  {
    imgPath: "./assets/images/gas icon.svg",
    name: "Diesel",
    title: "Diesel",
    id: 15,
  },

  {
    imgPath: "./assets/images/Armed Security.svg",
    name: "Armed Security",
    title: "Armed Security",
    id: 16,
  },
  {
    imgPath: "./assets/images/Gaming Zone.svg",
    name: "Gaming Zone",
    title: "Gaming Zone",
    id: 17,
  },
];
