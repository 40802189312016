import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getOrganizationList } from "../../services/organizations";
import { setSelectedOrg } from "../../reducers/organizationSlice";
//Context
import { useSidebarContext } from "../../Contexts/SidebarContext";

import { showToast } from "../../utils/tools";
import { useNavigate } from "react-router";
import { getMessageText } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import { useStyles } from "../../hooks/useStyles";
import PaperComponent from "../DraggableComponent/PaperComponent";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import CDSelect from "../citridotSelect/CitriDotSelect";
import { setCompanies, setParkingLots } from "../../reducers/dashboardSlice";
import { clearAllAminitiesDuration } from "../../reducers/finparkReserveSlice";
interface CustomNodeYesProps {
  show: boolean;
  onCancelDialogue: Function;
}

const btnStyle = { marginTop: 10 };

const SwitchOrganization = ({ show, onCancelDialogue }: CustomNodeYesProps) => {
  const dispatch = useDispatch();
  const api = useAxiosPrivate();
  const { changeUser, user, changeUserName, name, switchOrg } =
    useSidebarContext();
  const [selectedOrgName, setSelectedOrgName] = useState<any>({});
  // const [open, setOpen] = useState(false);

  const [organization, setOrganization] = useState<any>([]);

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === "name") {
      switchOrg(newValue);
    }
  };

  const handleChange = (e: any) => {
    switchOrg(e);
    localStorage.setItem("name", e);
  };

  // useEffect(() => {
  //   switchOrg(localStorage.getItem("name") || "");
  //   window.addEventListener("storage", onStorageUpdate);
  //   return () => {
  //     window.removeEventListener("storage", onStorageUpdate);
  //   };
  // }, []);

  useEffect(() => {
    if (show) {
      getOrganizationList(api, true, (data: any) => {
        setOrganization(data);
        dispatch(setCompanies(data));
      });
    }
  }, [show, changeUser]);
  const navigate = useNavigate();
  const initialValues = {
    organizationList: show ? user : "",
  };
  const validationSchema = Yup.object().shape({});
  const handleClose = () => {
    onCancelDialogue();
  };
  const backToAdmin = () => {
    //  changeUser(1);
    if (organization?.length > 0) {
      const getOrgName = organization.find((org: any) => org.orgId === 1);
      if (getOrgName?.orgName !== null) {
        changeUserName(getOrgName.orgName);
        changeUser(1);
        LocalStorageUtil.setItem("orgName", JSON.stringify(getOrgName.orgName));
        LocalStorageUtil.setItem(
          "saasOrgId",
          JSON.stringify(getOrgName.saasOrgId)
        );
        navigate("/mapDashboard");
        dispatch(
          setSelectedOrg(
            String(getOrgName.saasOrgId ? JSON.parse(getOrgName.saasOrgId) : 1)
          )
        );
        dispatch(clearAllAminitiesDuration());
      }
    }

    onCancelDialogue();
  };
  const onHandleSave = (value: any) => {
    if (selectedOrgName?.orgId === LocalStorageUtil.getItem("saasOrgId")) {
      showToast("WARNING", "The selected organisation is already loaded");
    } else {
      changeUser(value.organizationList);
      if (organization?.length > 0) {
        const getOrgName = organization.find(
          (org: any) => org.orgId === value.organizationList
        );
        if (getOrgName?.orgName !== null) {
          changeUserName(getOrgName.orgName);
          LocalStorageUtil.setItem(
            "orgName",
            JSON.stringify(getOrgName.orgName)
          );
          localStorage.setItem("orgName", JSON.stringify(getOrgName.orgName));
        }
      }
      LocalStorageUtil.setItem(
        "saasOrgId",
        JSON.stringify(value.organizationList)
      );
      LocalStorageUtil.setItem(
        "selectedInternalOrg",
        JSON.stringify(value.organizationList)
      );
      //handleChange(JSON.stringify(value.organizationList));
      localStorage.setItem("saasOrgId", JSON.stringify(value.organizationList));
      navigate("/mapDashboard");
      dispatch(
        setSelectedOrg(
          String(
            value.organizationList ? JSON.parse(value.organizationList) : 1
          )
        )
      );
      dispatch(clearAllAminitiesDuration());
      onCancelDialogue();
    }
  };

  const onSubmit = (values: any, props: any) => {
    onHandleSave(values);
    props?.formik.resetForm();
  };

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={show}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          sx: {
            width: "45%",
            maxHeight: "70%",
          },
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {getMessageText(LangConst.SWITCH_ORGANIZATION, "Switch Organization")}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form noValidate>
                <div className="col-lg-15 my-3">
                  <div className="mb-0">
                    {/* <div className="col-lg-3 my-3">
                                                <div className="mb-0"> */}
                    <FormControl fullWidth>
                      {/* <InputLabel style={{ top: "-5px" }}>
                        {getMessageText(
                          LangConst.SELECT_AN_ORGANIZATION,
                          " Select an Organization"
                        )}
                      </InputLabel>
                      <Select
                        name="organizationList"
                        label="Select an Organization"
                        style={{
                          borderRadius: "0.8rem",
                          height: "46px",
                        }}
                        value={
                          formik.values.organizationList
                            ? formik.values.organizationList
                            : 116
                        }
                        size="small"
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        onChange={(event: any) => {
                          formik.setFieldValue(
                            "organizationList",
                            event?.target.value
                          );
                        }}
                      >
                        {organization && organization?.length > 0 ? (
                          organization?.map((org: any, index: any) => (
                            <MenuItem value={org.saasOrgId} key={index}>
                              {org.orgName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value={""}></MenuItem>
                        )}
                      </Select> */}
                      <CDSelect
                        data={organization} //parkingLot, companyData
                        onChange={(e: any) => {
                          formik.setFieldValue(
                            "organizationList",
                            e !== null
                              ? e.saasOrgId
                              : {
                                  orgId: 1,
                                  orgName: "Finpark Inc",
                                  saasOrgId: 1,
                                }
                          );
                          setSelectedOrgName(e !== null ? e : "");
                          //FP-671: Login or Switching to Parking lot displaying all the Parking lot Information
                          dispatch(setParkingLots([]));
                        }}
                        value={{
                          saasOrgId: LocalStorageUtil.getItem("saasOrgId"),
                          orgName: JSON.parse(
                            String(LocalStorageUtil.getItem("orgName"))
                          ),
                        }}
                        label={getMessageText(
                          LangConst.SELECT_AN_ORGANIZATION,
                          "Select an Organization"
                        )}
                        onSelect={(e: any) => setSelectedOrgName(e)}
                      />
                    </FormControl>
                  </div>
                </div>

                {/* <Button type="submit" style={btnStyle}>
                  Save
                </Button>
                <Button style={btnStyle} onClick={handleClose}>
                  Cancel
                </Button> */}

                <div className="modal-footer px-0">
                  <button
                    type="button"
                    className="btn min btn-outline-dark mx-lg-3 mx-md-3 font-size-12 text-uppercase"
                    data-bs-dismiss="modal"
                    disabled={
                      +LocalStorageUtil.getItem("saasOrgId") === 1
                        ? true
                        : false
                    }
                    onClick={backToAdmin}
                  >
                    {getMessageText(LangConst.BACK_ADMIN, "Back to Admin")}
                  </button>
                  <button
                    type="button"
                    className="btn min btn-outline-dark mx-lg-3 font-size-12 text-uppercase"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    {getMessageText(LangConst.CLOSE, "Close")}
                  </button>
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn min btn-primary font-size-12 text-uppercase"
                      disabled={
                        selectedOrgName.length === 0 ||
                        selectedOrgName?.orgId ===
                          +LocalStorageUtil.getItem("saasOrgId")
                          ? true
                          : false
                      }
                    >
                      {getMessageText(LangConst.SWITCH, "Switch")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default SwitchOrganization;

//onClick={onSubmit}
